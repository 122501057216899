.ag-theme-alpine {
  --ag-row-hover-color: rgba(241, 242, 243, 1);
  --ag-border-color: transparent;
  --ag-header-background-color: #d0eae4;
  --ag-header-column-resize-handle-color: #000000;
}

.ag-theme-alpine .ag-row-even {
  border-bottom-color: rgba(0, 0, 0, 0.020);
}

.ag-theme-alpine .ag-row-odd {
  border-bottom-color: rgba(0, 0, 0, 0.020);
}

.ag-theme-alpine .ag-cell {
  display: flex;
  align-items: center;
}

.ag-theme-alpine .ag-row:hover {
  cursor: pointer;
}
